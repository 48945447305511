var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',_vm._b({staticClass:"no-print",attrs:{"id":"core-navigation-drawer","expand-on-hover":false,"right":false,"app":"","width":"260"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"ma-1",attrs:{"src":require("@/assets/unimi_logo.png"),"max-width":"250","max-heigth":"250"}})],1),_c('v-sheet',{staticClass:"pa-4",attrs:{"color":"secondary","dark":true,"elevation":"2","align":"center"}},[(this.$store.state.userInitials)?_c('v-avatar',{staticClass:"mb-4",attrs:{"color":"success","size":"64"}},[(_vm.userInitials)?_c('h1',[_vm._v(_vm._s(_vm.userInitials.toUpperCase()))]):_vm._e()]):_vm._e(),_c('div',[(_vm.userRole)?_c('h3',[_vm._v(_vm._s(_vm.userRole.toUpperCase()))]):_vm._e()])],1),_c('v-list',{attrs:{"flat":""}},[_vm._l((_vm.filteredItems),function(item,index){return _c('v-list-group',{key:index,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('router-link',{attrs:{"to":item.path ? item.path : ''}},[_vm._v(_vm._s(item.title))])],1)],1)]},proxy:true}],null,true)},_vm._l((item.children),function(child,i){return _c('v-list-item',{key:i,staticClass:"pl-6"},[_c('div',{attrs:{"title":child.tooltip}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":child.path}},[_vm._v(_vm._s(child.title))])],1)],1)])}),1)}),(
        _vm.userRole === 'admin' ||
          _vm.userRole === 'operator' ||
          _vm.userRole === 'auditor' ||
          _vm.userPrivacy === 'write' ||
          _vm.userPrivacy === 'read'
      )?_c('v-list-item',[_c('div',{attrs:{"title":"Ricerca del personale"}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":"/users"}},[_vm._v("PERSONE")])],1)],1)]):_vm._e(),(
        _vm.userRole === 'admin' ||
          _vm.userRole === 'operator' ||
          _vm.userRole === 'auditor'
      )?_c('v-list-item',[_c('div',{attrs:{"title":"Ricerca delle stanze"}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":"/rooms"}},[_vm._v("STANZE")])],1)],1)]):_vm._e(),(_vm.userRole === 'admin' || _vm.userRole === 'operator')?_c('v-list-item',[_c('div',{attrs:{"title":"Ultimi aggiornamenti con database centrale"}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":"/sync"}},[_vm._v("SINCRONIZZAZIONE")])],1)],1)]):_vm._e(),(_vm.userRole === 'admin')?_c('v-list-item',[_c('div',{attrs:{"title":"Invia comunicazioni"}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":"/texting"}},[_vm._v("AVVISI")])],1)],1)]):_vm._e(),(_vm.userRole === 'admin')?_c('v-list-item',[_c('div',{attrs:{"title":"Lista containers"}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":"/containers"}},[_vm._v("CONTAINERS")])],1)],1)]):_vm._e(),(
        _vm.userRole === 'admin' ||
          _vm.userRole === 'operator' ||
          _vm.userRole === 'auditor' ||
          _vm.userRole === 'user'
      )?_c('v-list-item',[_c('div',{attrs:{"title":"Dati personali"}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":"/mydata"}},[_vm._v("I MIEI DATI")])],1)],1)]):_vm._e(),(
        _vm.userRole === 'admin' ||
          _vm.userRole === 'operator' ||
          _vm.userRole === 'auditor' ||
          _vm.userRole === 'user'
      )?_c('v-list-item',[_c('div',{attrs:{"title":"Stanze di cui sei responsabile"}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":"/myrooms"}},[_vm._v("LE MIE STANZE")])],1)],1)]):_vm._e(),(
        _vm.userRole === 'admin' ||
          _vm.userRole === 'operator' ||
          _vm.userRole === 'auditor' ||
          _vm.userRole === 'user'
      )?_c('v-list-item',[_c('div',{attrs:{"title":"Beni di cui sei responsabile"}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":"/myassets"}},[_vm._v("I MIEI BENI")])],1)],1)]):_vm._e(),(
        _vm.userRole === 'admin' ||
          _vm.userRole === 'operator' ||
          _vm.userRole === 'auditor' ||
          _vm.userRole === 'user'
      )?_c('v-list-item',[_c('div',{attrs:{"title":"I tuoi acquisti"}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":"/mypurchases"}},[_vm._v("I MIEI ACQUISTI")])],1)],1)]):_vm._e(),(
        _vm.userRole === 'admin' ||
          _vm.userRole === 'operator' ||
          _vm.userRole === 'auditor' ||
          _vm.userRole === 'user'
      )?_c('v-list-item',[_c('div',{attrs:{"title":"I tuoi container"}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":"/mycontainers"}},[_vm._v("I MIEI CONTAINER")])],1)],1)]):_vm._e(),(
        _vm.userRole === 'admin' ||
          _vm.userRole === 'operator' ||
          _vm.userRole === 'auditor' ||
          _vm.userRole === 'user'
      )?_c('v-list-item',[_c('div',{attrs:{"title":"I tuoi housing"}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":"/myhousings"}},[_vm._v("I MIEI HOUSING")])],1)],1)]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }