<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    class="no-print"
    v-model="drawer"
    :expand-on-hover="false"
    :right="false"
    app
    width="260"
    v-bind="$attrs"
  >
    <router-link to="/"
      ><v-img
        class="ma-1"
        src="@/assets/unimi_logo.png"
        max-width="250"
        max-heigth="250"
    /></router-link>
    <v-sheet
      color="secondary"
      :dark="true"
      class="pa-4"
      elevation="2"
      align="center"
    >
      <v-avatar v-if="this.$store.state.userInitials" class="mb-4" color="success" size="64">
        <h1 v-if="userInitials">{{ userInitials.toUpperCase() }}</h1>
      </v-avatar>
      <div>
        <h3 v-if="userRole">{{ userRole.toUpperCase() }}</h3>
      </div>
    </v-sheet>
    <v-list flat>
      <v-list-group
        v-for="(item, index) in filteredItems"
        :key="index"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title
              ><router-link :to="item.path ? item.path : ''">{{
                item.title
              }}</router-link></v-list-item-title
            >
          </v-list-item-content>
        </template>

 
        <v-list-item
          class="pl-6"
          v-for="(child, i) in item.children"
          :key="i"
        >    
         <div :title="child.tooltip">
          <v-list-item-content>
            <router-link :to="child.path" >{{ child.title }}</router-link>
          </v-list-item-content>
         </div>
        </v-list-item>

        
      </v-list-group>
      <v-list-item
        v-if="
          userRole === 'admin' ||
            userRole === 'operator' ||
            userRole === 'auditor' ||
            userPrivacy === 'write' ||
            userPrivacy === 'read'
        "
      >
      <div title="Ricerca del personale">
        <v-list-item-content
          ><router-link to="/users">PERSONE</router-link></v-list-item-content
        ></div>
      </v-list-item>
      <v-list-item
        v-if="
          userRole === 'admin' ||
            userRole === 'operator' ||
            userRole === 'auditor'
        "
      >
      <div title="Ricerca delle stanze">
        <v-list-item-content
          ><router-link to="/rooms">STANZE</router-link></v-list-item-content
        >
        </div>
      </v-list-item>
      <v-list-item v-if="userRole === 'admin' || userRole === 'operator'">
        <div title="Ultimi aggiornamenti con database centrale">
        <v-list-item-content
          ><router-link to="/sync"
            >SINCRONIZZAZIONE</router-link
          ></v-list-item-content
        ></div>
      </v-list-item>
      <v-list-item v-if="userRole === 'admin'">
        <div title="Invia comunicazioni">
        <v-list-item-content
          ><router-link to="/texting"
            >AVVISI</router-link
          ></v-list-item-content
        ></div>
      </v-list-item>
      <v-list-item v-if="userRole === 'admin'">
        <div title="Lista containers">
        <v-list-item-content
          ><router-link to="/containers"
            >CONTAINERS</router-link
          ></v-list-item-content
        ></div>
      </v-list-item>
      <v-list-item
        v-if="
          userRole === 'admin' ||
            userRole === 'operator' ||
            userRole === 'auditor' ||
            userRole === 'user'
        "
      ><div title="Dati personali">
        <v-list-item-content
          ><router-link to="/mydata"
            >I MIEI DATI</router-link
          ></v-list-item-content
        ></div>
      </v-list-item>
      <v-list-item
        v-if="
          userRole === 'admin' ||
            userRole === 'operator' ||
            userRole === 'auditor' ||
            userRole === 'user'
        "
      ><div title="Stanze di cui sei responsabile">
        <v-list-item-content
          ><router-link to="/myrooms"
            >LE MIE STANZE</router-link
          ></v-list-item-content
        ></div>
      </v-list-item>
      <v-list-item
        v-if="
          userRole === 'admin' ||
            userRole === 'operator' ||
            userRole === 'auditor' ||
            userRole === 'user'
        "
      ><div title="Beni di cui sei responsabile">
        <v-list-item-content
          ><router-link to="/myassets"
            >I MIEI BENI</router-link
          ></v-list-item-content
        ></div>
      </v-list-item>
      <v-list-item
        v-if="
          userRole === 'admin' ||
            userRole === 'operator' ||
            userRole === 'auditor' ||
            userRole === 'user'
        "
      ><div title="I tuoi acquisti">
        <v-list-item-content
          ><router-link to="/mypurchases"
            >I MIEI ACQUISTI</router-link
          ></v-list-item-content
        ></div>
      </v-list-item>
    <v-list-item
        v-if="
          userRole === 'admin' ||
            userRole === 'operator' ||
            userRole === 'auditor' ||
            userRole === 'user'
        "
      ><div title="I tuoi container">
        <v-list-item-content
          ><router-link to="/mycontainers"
            >I MIEI CONTAINER</router-link
          ></v-list-item-content
        ></div>
      </v-list-item>
      <v-list-item
        v-if="
          userRole === 'admin' ||
            userRole === 'operator' ||
            userRole === 'auditor' ||
            userRole === 'user'
        "
      ><div title="I tuoi housing">
        <v-list-item-content
          ><router-link to="/myhousings"
            >I MIEI HOUSING</router-link
          ></v-list-item-content
        ></div>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    items: [
      {
        title: "BENI",
        roles: ["admin", "operator", "auditor"],
        children: [
          {
            title: "RICERCA CON NUMERO INV.",
            roles: ["admin", "operator", "auditor"],
            path: "/assets",
            tooltip: "Ricerca tramite il nuovo numero di inventario",
          },
          {
            title: "RICERCA GENERICA",
            roles: ["admin", "operator", "auditor"],
            path: "/assetssearch",
            tooltip: "Ricerca complessa tramite vari parametri",
          },
          {
            title: "RICERCA CON OLD INV.",
            roles: ["admin", "operator", "auditor"],
            path: "/old",
            tooltip: "Ricerca tramite il vecchio numero di inventario",
          },
        ],
      },
      {
        title: "ACQUISTI",
        roles: ["admin", "operator", "auditor"],
        children: [
          {
            title: "LISTA ACQUISTI",
            roles: ["admin", "operator", "auditor"],
            path: "/purchases",
            tooltip: "Lista acquisti",
          },
          {
            title: "RICERCA GENERICA",
            roles: ["admin", "operator", "auditor"],
            path: "/purchasessearch",
            tooltip: "Ricerca tramite vari parametri",
          },
        ],
      },
      {
        title: "RICOGNIZIONE",
        roles: ["admin", "operator", "auditor"],
        children: [
          {
            title: "AMMINISTRAZIONE",
            roles: ["admin"],
            path: "/ammricognizione",
          },
          {
            title: "DEVICE",
            roles: ["admin"],
            path: "/connectRfidDevice",
          },
          {
            title: "ETICHETTE BENI",
            roles: ["admin"],
            path: "/print_labels",
          },
          {
            title: "ETICHETTE STANZE",
            roles: ["admin"],
            path: "/print_rooms_labels",
          },
          {
            title: "PRIMA RICOGNIZIONE",
            roles: ['admin','operator'],
            path: "/prima_ricognizione",
          },
          {
            title: "RICOGNIZIONE",
            roles: ["admin", "operator"],
            path: "/ricognizione",
          },
          {
            title: "REPORT",
            roles: ["admin", "operator", "auditor"],
            path: "/reporicognizione",
          },
        ],
      },
    ],
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },

    // Just show menus available for the role
    filteredItems() {
      let filtered = [];
      let myItem = {};
      let myChildren = [];
      // console.log('UserRole: ' + this.userRole);
      for (let item of this.items) {
        if (item.roles.includes(this.userRole)) {
          myChildren = [];
          for (let c of item.children) {
            if (c.roles.includes(this.userRole)) {
              myChildren.push(c);
            }
          }
          myItem = { ...item };
          myItem.children = myChildren;

          filtered.push(myItem);
        }
      }

      return filtered;
    },
    ///////////////////////////////////////

    // extract from vuex users initials and role
    ...mapState(["userInitials", "userRole", "userPrivacy"]),
  },
};
</script>

<style scoped>
#core-navigation-drawer {
  background-color: #ffffff;
}
.v-list-item--link::before {
  background-color: transparent;
}


@media print {
  .no-print {
    display: none;
    padding: 0 !important;
  }
}

</style>
